import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { AppInstances } from './CountrSdk'

class DeviceError extends Error {}
export default class DeviceUtils {
  static countrSdk

  /**
   * Set embeded device if device id is passed at url params
   *
   * @param {Object} match
   * @param {Function} setEmbededDevice
   */
  static checkEmbededDevice(device, setEmbededDevice) {
    !!device && setEmbededDevice(device)
  }

  /**
   * Generate Machine UUID based on components computed
   * Currently avoiding user Browser and language of user machine
   */
  static async generateUUID() {
    const fpPromise = FingerprintJS.load({ token: 'qWrdj7r6z8RlqD9KlAwy' })
    // const fpPromise = FingerprintJS.load()
    const fp = await fpPromise
    const result = await fp.get({ extendedResult: true })
    const devicefingerprintid = 'kds-' + result.visitorId
    localStorage.setItem('uuid', devicefingerprintid)
    return devicefingerprintid
  }

  /**
   * Receive Device UUID as parameter and query Countr API
   *
   * @throws This method will throw an error if failed and should be treat
   * on caller/app side with Error Boundaries
   *
   * @param {String} uuid
   */
  static async fetchDeviceUUID(uuid) {
    const countr = await AppInstances.getCountrSdk()
    // const device = await countr.devices.search({ text: uuid })
    const device = await countr.devices.readOne(uuid)

    if (!device || !Object.keys(device).length) return false

    return device
  }

  /**
   * Receive Store _id as parameter and query Countr API
   *
   * @param {String} id Store Id from Device Object
   */
  static async fetchDeviceStore(id) {
    const countr = await AppInstances.getCountrSdk()
    const store = await countr.stores.readOne(id)

    if (!store || !Object.keys(store).length) return false

    if (store.devices) delete store.devices

    return store
  }

  /**
   * Update device object at database
   *
   * @param {Object} Device Object
   */
  static async updateDeviceSettings(device) {
    if (!device._id) {
      throw new DeviceError('Invalid Device Id passed to update Settings')
    }

    device.info = {
      ...device.info,
      version: 'kds - ' + process.env.REACT_APP_VERSION
    }

    return DeviceUtils.countrSdk.devices.update(device._id, device)
  }

  /**
   * Update Redux's settings getting the value from loaded device
   *
   * @param {Object} Device object
   * @param {Object} Initial redux settings
   * @param {Function} Set redux settings action
   */
  static setDeviceSetttings(device, initialSettings, setSettings) {
    if (device.settings) {
      Object.keys(initialSettings).forEach(settingKey => {
        if (device.settings[settingKey]) {
          let value = device.settings[settingKey].value

          // Small fix for old devices that has settings.language = 'en'
          if (settingKey === 'language' && !value) {
            value = device.settings[settingKey]
          }

          setSettings({
            key: settingKey,
            value: value
          })
        }
      })
    }
  }

  /**
   * Default hotkeys to change orders status
   *
   */
  static getDefaultHotKeys() {
    return {
      new: 'n',
      preparing: 'p',
      ready: 'r',
      completed: 'c'
    }
  }

  /**
   * Return devices set commands to work with the hotkeys
   *
   * @param {Object} Device Object
   */
  static mapHotKeys(device) {
    const STATUS = ['new', 'preparing', 'ready', 'completed']
    const DEFAULT = 'ctrl+n,ctrl+p,ctrl+r,ctrl+c'

    if (!device) {
      return DEFAULT
    }

    if (!!device.settings && !!device.settings.hotkeys) {
      return STATUS.map(st => `ctrl+${device.settings.hotkeys[st]}`).join(',')
    }

    return DEFAULT
  }
}
