import {
  SET_LOADING,
  SHOW_TOAST,
  DISMISS_TOAST,
  SET_USER,
  SET_PAGE_INDEX,
  SET_EMBEDDED_DEVICE,
  SET_MAX_ORDERS_PAGES,
  SET_CURRENT_GROUPS
} from './actionTypes'

export const setCurrentGroups = groups => {
  return {
    type: SET_CURRENT_GROUPS,
    payload: groups
  }
}

export const setUser = user => ({
  type: SET_USER,
  payload: user
})

export const setEmbededDevice = deviceId => {
  return {
    type: SET_EMBEDDED_DEVICE,
    payload: deviceId
  }
}

export const setLoading = status => ({
  type: SET_LOADING,
  payload: status
})

export const showToast = msg => ({
  type: SHOW_TOAST,
  payload: msg
})

export const dismissToast = () => ({
  type: DISMISS_TOAST
})

export const setPageIndex = index => ({
  type: SET_PAGE_INDEX,
  payload: index
})

export const setMaxOrdersPages = max => ({
  type: SET_MAX_ORDERS_PAGES,
  payload: max
})
