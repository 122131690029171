import { memo, useState, useEffect, useRef, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Text } from 'react-internationalization'

import { useSearchParams, useNavigate, useParams } from 'react-router-dom'

import { Utils as UtilsPkg, StoreUtils as StoreUtilsPkg } from '@countr/utils'
import CountrResources from '../utils/CountrResources'
import DeviceUtils from '../utils/DeviceUtils'
import Utils from '../utils/Utils'
import { AppInstances } from '../utils/CountrSdk'

import { withRouter } from '../wrappers/routeWrappers'

import {
  setLoading,
  showToast,
  setUser,
  setEmbededDevice
} from '../store/actions/app'
import {
  setStore,
  setEmployees,
  setStoreId,
  setAssignedStores
} from '../store/actions/store'
import { setDevice } from '../store/actions/device'
import { setSettingsItem } from '../store/actions/settings'

import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Logo from '../components/Shared/Logo'

import './../styles/Login.scss'

const APP_VERSION = process.env.REACT_APP_VERSION
const MERCHANT = 'Merchant'

// const { REACT_APP_SERVICE_NAME, REACT_APP_ERROR_SOURCE } = process.env
const { REACT_APP_SERVICE_NAME } = process.env
const kdsServiceName = REACT_APP_SERVICE_NAME || 'kds'

const mapStateToProps = state => {
  return {
    isLoading: state.app.isLoading,
    settings: state.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setEmbededDevice: deviceId => dispatch(setEmbededDevice(deviceId)),
    setLoading: status => dispatch(setLoading(status)),
    showToast: msg => dispatch(showToast(msg)),
    setUser: user => dispatch(setUser(user)),
    setStore: store => dispatch(setStore(store)),
    setEmployees: employees => dispatch(setEmployees(employees)),
    setDevice: store => dispatch(setDevice(store)),
    setStoreId: storeId => dispatch(setStoreId(storeId)),
    setSettingsItem: item => dispatch(setSettingsItem(item)),
    setAssignedStores: st => dispatch(setAssignedStores(st))
  }
}

const LoginPage = memo(props => {
  
  const resources = useRef(new CountrResources())
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [rememberPassword, setRemeberPassword] = useState(true)

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()

  const { embedDevice } = useParams();

  const dispatch = useDispatch()
  const setAppStores = useCallback(
    stores => dispatch({ type: 'SET_STORES', payload: stores }),
    [dispatch]
  )

  const navigate = useNavigate()

  useEffect(() => {
    checkAutoLogin()
    Utils.getNotificationPermission()
    localStorage.setItem('rememberPassword', 'true')

    return () => {
      resources.current = null
    }
  }, [checkAutoLogin])

  const { setLoading } = props

  const checkAutoLogin = useCallback(async () => {
    if (searchParams?.accessToken) {
      localStorage.setItem('access_token', searchParams.accessToken)
      localStorage.setItem('rememberPassword', true)
    }

    const remember = JSON.parse(localStorage.getItem('rememberPassword'))
    const token = localStorage.getItem('access_token')

    if (!!remember && token) {
      setLoading(true)

      try {
        const user = await resources.current.autoLogin()
        if (user.__t !== MERCHANT) {
          localStorage.removeItem('access_token')
          localStorage.removeItem('rememberPassword')
          throw new Error(
            'Access denied! Please try to login with Master account.'
          )
        }
        successLogin(user)
      } catch (error) {
        errorLogin(error)
      }
    }
  }, [errorLogin, searchParams?.accessToken, setLoading, successLogin])

  const {
    setAssignedStores,
    setStore,
    setStoreId,
    setEmployees,
    setDevice,
    settings,
    setSettingsItem,
    setEmbededDevice,
    setUser,
    showToast
  } = props

  const successLogin = useCallback(
    async user => {
      try {
        setUser(user)
        const localuuid = localStorage.getItem('uuid')

        const uuid = localuuid || (await DeviceUtils.generateUUID())

        if (uuid) {
          const countr = await AppInstances.getCountrSdk()
          DeviceUtils.checkEmbededDevice(embedDevice, setEmbededDevice)

          const device = await DeviceUtils.fetchDeviceUUID(uuid)

          if (device) {
            //Temporary fix to correct old UUIDs on server
            if (localuuid && !(localuuid.indexOf('kds-') === 0)) {
              //Old UUID
              const newuuid = await DeviceUtils.generateUUID()
              countr.devices
                .update(device._id, {
                  uuid: newuuid
                })
                .catch(err => console.log(err))
            }

            // Select all stores that the account has
            const storesPaginated = await UtilsPkg.paginate(
              countr,
              'stores',
              10
            )

            setAppStores(
              storesPaginated.map(store => {
                return {
                  name: store.name,
                  _id: store._id
                }
              })
            )
            
            // Get the current registered store to check for KDS inside extra.services
            const store = await DeviceUtils.fetchDeviceStore(device.store)
            await StoreUtilsPkg.addOrRemoveStoreServices(
              countr,
              store,
              kdsServiceName
            )

            if (device.settings?.storesListener) {
              const parsedStores = device.settings.storesListener.map(store => {
                const findStore = storesPaginated.find(
                  _st => _st._id === store || store._id
                )

                return {
                  name: findStore.name,
                  _id: findStore._id
                }
              })

              
              const listenedStores = device.settings.storesListener.map(store =>
                storesPaginated.find(_st => _st._id === store || store._id)
              )

              listenedStores.forEach(async listenedStore => {
                await StoreUtilsPkg.addOrRemoveStoreServices(
                  countr,
                  listenedStore,
                  kdsServiceName
                )
              });


              setAssignedStores(parsedStores)
            }

            setStore(store)
            setStoreId(store._id)
            setEmployees(store.employees)
            setDevice(device)

            DeviceUtils.setDeviceSetttings(device, settings, setSettingsItem)

            navigate(device && store ? '/main' : '/store')
          } else {
            navigate('/store')
          }
        }
      } catch (error) {
        console.log('🚀 ~ file: LoginPage.js ~ line 233 ~ error', error)
        // AppInstances.logSlackError({
        //   user: user._id,
        //   store: store._id,
        //   device: device._id,
        //   source: REACT_APP_ERROR_SOURCE,
        //   date: new Date().toISOString(),
        //   message: error.emssage
        // })

        navigate('/store')
      }

      setLoading(false)
    },
    [
      navigate,
      embedDevice,
      // searchParams?.device,
      setAppStores,
      setAssignedStores,
      setDevice,
      setEmbededDevice,
      setEmployees,
      setLoading,
      setSettingsItem,
      setStore,
      setStoreId,
      setUser,
      settings
    ]
  )

  const errorLogin = useCallback(
    error => {
      setLoading(false)
      const msg = error.message ? error.message : JSON.stringify(error)
      showToast(JSON.stringify(msg))
    },
    [setLoading, showToast]
  )

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleLoginButton()
    }
  }

  const handleLoginButton = () => {
    if (props.isLoading) {
      return
    }

    try {
      login().then(user => successLogin(user))
    } catch (error) {
      errorLogin(error)
    }
  }

  const login = () => {
    return new Promise(resolve => {
      try {
        resources.current.login(username, password).then(user => {
          if (user.__t !== MERCHANT) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('rememberPassword')
            throw new Error(
              'Access denied! Please try to login with Master account.'
            )
          }

          resolve(user)
        })
      } catch (error) {
        errorLogin(error)
      }
    })
  }

  return (
    <div id="app-login">
      <div className="login-content">
        <Logo />
        <TextField
          label={<Text id="username" />}
          autoFocus
          color="secondary"
          variant="outlined"
          margin="normal"
          type="email"
          fullWidth
          value={username}
          onChange={event => setUsername(event.target.value)}
          onKeyPress={handleKeyPress}
        />

        <TextField
          label={<Text id="password" />}
          fullWidth
          color="secondary"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={event => setPassword(event.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <Icon>{showPassword ? 'visibility' : 'visibility_off'}</Icon>
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          className="login-button-width"
          variant="contained"
          color="secondary"
          onClick={handleLoginButton}>
          {props.isLoading ? (
            <CircularProgress className="btn-loader" />
          ) : (
            <Text id="sign_in" />
          )}
        </Button>
        <FormGroup className="login-form-control-row">
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberPassword}
                onChange={event => {
                  setRemeberPassword(event.target.checked)
                  localStorage.setItem(
                    'rememberPassword',
                    JSON.stringify(event.target.checked)
                  )
                }}
              />
            }
            label={<Text id="remember_password" />}
          />
        </FormGroup>
        <p>{APP_VERSION}</p>
      </div>
    </div>
  )
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
)
