import { SET_SETTINGS_ITEM } from '../actions/actionTypes'

const initialState = {
  // EXAMPLE FORMAT
  // { key: 'categories', type: 'array', label: 'categories', value: [] },
  // { key: 'orderPerPage', type: 'number', label:
  // 'order_per_page', value: 8, min: 0, max: 30 }, -> Number must have min/max
  // { key: 'test_bool', type: 'boolean', label: 'test_bool', value: false },
  // { key: 'test_string', type: 'string', label: 'test_string', value: 'hahahaha' }
  // CURRENT SETTINGS OPTIONS
  warning_delay: {
    type: 'number',
    label: 'warning_delay',
    value: 0,
    min: 0,
    max: 60
  },
  load_orders_past: {
    type: 'number',
    label: 'load_orders_past',
    value: 1,
    min: 1,
    max: 7
  },
  auto_refresh: {
    type: 'number',
    label: 'auto_refresh',
    value: 0,
    min: 0,
    max: 10
  },
  auto_complete_order: {
    type: 'number',
    label: 'auto_complete_order',
    value: 0,
    min: 0,
    max: 30
  },
  language: {
    type: 'array',
    label: 'language',
    options: ['en', 'nl', 'ar', 'pt', 'de', 'es', 'fr', 'is', 'it', 'el'],
    value: 'en'
  },
  show_employee_on_product: {
    type: 'boolean',
    label: 'show_employee_on_product',
    value: false
  },
  move_processing_ticket_first: {
    type: 'boolean',
    label: 'move_processing_ticket_first',
    value: false
  },
  show_categories_separator: {
    type: 'boolean',
    label: 'show_categories_separator',
    value: false
  },
  hide_completed_items: {
    type: 'boolean',
    label: 'hide_completed_items',
    value: false
  },
  enable_groups: {
    type: 'boolean',
    label: 'enable_groups',
    value: false
  },
  show_product_image: {
    type: 'boolean',
    label: 'show_product_image',
    value: false
  },
  show_product_media: {
    type: 'boolean',
    label: 'show_product_media',
    value: false
  },
  cancel_order_pin: {
    type: 'string', 
    label: 'cancel_order_pin',
    value: '1234'
  },
  printer_ip: {
    type: 'string', 
    label: 'printer_ip_ad',
    value: ''
  },
  print_delivery: {
    type: 'boolean', 
    label: 'print_new_online_order',
    value: true
  }
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS_ITEM: {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          value: action.payload.value
        }
      }
    }
    default:
      return state
  }
}

export default settingsReducer
