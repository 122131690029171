import { memo, useRef, useEffect, useCallback, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { translate } from 'react-internationalization'
import useWebSocket from 'react-use-websocket'
import TicketSound from '../assets/sounds/definite.mp3'
import ReactAudioPlayer from 'react-audio-player'
// Wrappers
import { withRouter } from '../wrappers/routeWrappers'

// Utils
import { AppInstances } from '../utils/CountrSdk'
import CountrResources from '../utils/CountrResources'

// Components
import Cart from '../components/Cart/Cart'
import Grouped from '../components/Grouped'

// Actions
import {
  setCarts,
  setPlayTicketSound,
  setCompletedCarts,
  setPrintDelivery
} from '../store/actions/carts'
import { setCategories } from '../store/actions/categories'
import { setDevices } from '../store/actions/device'

import CartUtils from '../utils/CartUtils'

// Redux Props
const mapStateToProps = state => {
  return {
    app: state.app,
    settings: state.settings,
    carts: state.carts,
    device: state.device.device,
    store: state.store.store
  }
}

// Redux Dispatch
const mapDispatchToProps = dispatch => {
  return {
    setCategories: categories => dispatch(setCategories(categories)),
    setDevices: devices => dispatch(setDevices(devices)),
    setCarts: carts => dispatch(setCarts(carts)),
    setCompletedCarts: carts => dispatch(setCompletedCarts(carts)),
    setPlayTicketSound: play => dispatch(setPlayTicketSound(play)),
    setPrintDelivery: info => dispatch(setPrintDelivery(info))
  }
}

const MainPage = memo(props => {
  const socketUrl = `${process.env.REACT_APP_AWS_WEBSOCKET_URL}`

  const [socketHasFailed, setSocketHasFailed] = useState(false)

  const resource = useRef(new CountrResources())
  const allStoresAssigned = useSelector(state => state.store.allStoresAssigned)

  const dispatch = useDispatch()
  const updateCart = useCallback(
    cart => dispatch({ type: 'UPDATE_CART', payload: { cart } }),
    [dispatch]
  )

  const setShowingCarts = useCallback(
    carts => dispatch({ type: 'SET_SHOWING_CARTS', payload: carts }),
    [dispatch]
  )

  const setEmployees = useCallback(
    employees => dispatch({ type: 'SET_EMPLOYEES', payload: employees }),
    [dispatch]
  )

  const setCompletedCarts = useCallback(
    carts => dispatch({ type: 'COMPLETED_CARTS', payload: carts }),
    [dispatch]
  )

  const setCartToTransaction = useCallback(
    cart => dispatch({ type: 'SET_CART_TO_TRANSACTION', payload: { cart } }),
    [dispatch]
  )

  const setLoading = useCallback(
    status => dispatch({ type: 'SET_LOADING', payload: status }),
    [dispatch]
  )

  const { playTicketSound, printDelivery } = props.carts
  const { setPlayTicketSound, setPrintDelivery } = props

  const kitchenCategories =
    props.device?.settings?.web_settings?.kitchen_categories.length || 0

  // Refresh order after categories change
  useEffect(() => {
    refreshOrders()
  }, [kitchenCategories, refreshOrders])

  useEffect(() => {
    console.log('Initial Render Effect Recovery Daily...')
    initCategories()
    initDevices()
    initEmployees()

    refreshOrders()

    return () => {
      setSocketHasFailed(true)
    }
  }, [initEmployees, initCategories, initDevices, refreshOrders, setLoading])

  const sendSoundNotification = async() => {
    const targetDevice = localStorage.getItem('localDesktop')
    if(!targetDevice) return
    const countr = await AppInstances.getCountrSdk()
    const body = {
      message: '@playSound',
      status: 'info'
    }
    await countr.devices.readOne.notify(targetDevice, body)
  }

  const sendPrintDelivery = async cart => {
    try {
      if(!(localStorage.getItem('localDesktop') && cart)) return
      const countr = await AppInstances.getCountrSdk()

      await countr[
        `${cart.__t.charAt(0).toLowerCase()}${cart.__t.slice(1)}s`
      ].print(cart._id, {
        device: localStorage.getItem('localDesktop'),
        deliveryReceipt: true
      })
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    if (playTicketSound) {
      // Fire sound to device set up to listen for this
      sendSoundNotification()
      setTimeout(() => {
        setPlayTicketSound(false)
      }, 2000)
    }
  }, [playTicketSound, setPlayTicketSound])

  useEffect(() => {
    if (printDelivery && printDelivery._id) {
      // Fire print command to device set up to listen for this
      if(props.settings.print_delivery?.value) sendPrintDelivery(printDelivery)
      setPrintDelivery(null)
    }
  }, [printDelivery, props.settings.print_delivery?.value, setPrintDelivery])

  /**
   * Document it properly
   */
  const { lastJsonMessage } = useWebSocket(socketUrl, {
    retryOnError: true,
    share: true,
    reconnectAttempts: 30,
    reconnectInterval: 3000,
    queryParams: {
      user: props.app.user._id,
      store: props.device.store,
      device: props.device._id,
      listeningStores: allStoresAssigned?.length
        ? allStoresAssigned.map(store => store._id).join()
        : props.device.store
    },

    //what does the close event look like when caused by the 2 hour limit? Is there a
    // different error code that you could use? I would look into returning false in
    // this case and adding the following option:
    shouldReconnect: () => {
      // console.log('shouldReconnect - ', _closeEvent)
      // if (_closeEvent.code === 1006) {
      //   return false
      // }

      return true
    },
    onOpen: () => {
      // console.log(`socketHasFailed ${socketHasFailed}`)
      if (socketHasFailed) {
        console.log(`socketHasFailed is ${socketHasFailed}, refreshing orders`)
        refreshOrders()
      }
      console.log(`Open Socket`)
    },
    onClose: () => {
      setSocketHasFailed(true)
      console.log(`On Close Socket`)
    },
    onReconnectStop: data => console.log(`On Socket Reconnect Stop - ${data}`),
    onError: e => {
      setSocketHasFailed(true)
      console.log(`On Socket Error' ${e}`)
    },
    onMessage: () => console.log(`On Socket Message Received`)
  })

  /**
   * Use Effect for API Gateway Web Sockets
   * Receiving events for Transactions and Carts
   * If Deliverect or external_order we transform Cart into Transactions
   * but first update it with the new data
   */
  useEffect(() => {
    if (lastJsonMessage?.message) {
      const { message } = lastJsonMessage

      switch (message.__t) {
        case 'Transaction':
          if (lastJsonMessage.options?.actions === 'convertToTransaction') {
            setCartToTransaction(message)
          } else {
            updateCart(message)
          }
          break
        case 'Cart':
          updateCart(message)
          break
      }
    }
  }, [lastJsonMessage, setCartToTransaction, updateCart])

  /**
   * Get all Actives and Completed Carts and Transactions
   */
  const refreshOrders = useCallback(async () => {
    setLoading(true)
    const countr = await AppInstances.getCountrSdk()

    const carts = await resource.current.getOrders(
      'carts',
      countr,
      allStoresAssigned
    )

    const transactions = await resource.current.getOrders(
      'transactions',
      countr,
      allStoresAssigned
    )

    const ticketsList = carts.actives.concat(transactions.actives)

    setShowingCarts(ticketsList.sort(CartUtils.compareOrderTime))
    // setShowingCarts(ticketsList)
    setCompletedCarts(carts.completes.concat(transactions.completes))
    setLoading(false)
  }, [allStoresAssigned, setLoading, setShowingCarts, setCompletedCarts])

  // Move it to use the one "paginate" that we added to Countr Utils
  const { setCategories } = props
  const initCategories = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()
    const countCategories = await countr.categories.count()
    const loadedCategories = []
    let skip = 0

    while (loadedCategories.length < countCategories) {
      const res = await countr.categories.read({
        skip,
        limit: 50
      })
      skip += 50

      loadedCategories.push(...res)
    }

    setCategories(loadedCategories)
  }, [setCategories])

  const { setDevices } = props

  // !This is wrong and will break with big accounts, refactor
  // !and move it to Utils as well
  const initDevices = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()
    countr.devices.read({ sort: '-updated_at' }).then(devices => {
      if (devices && devices.length) {
        setDevices(devices.filter(device => device._id !== props.device._id))
      }
    })
  }, [props.device._id, setDevices])
  
  const initEmployees = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()
    countr.employees.read({ sort: '-updated_at' }).then(employees => {
      if (employees?.length) {
        setEmployees(employees)
      }
    })
  }, [setEmployees])

  // eslint-disable-next-line no-unused-vars
  const logError = async message => {
    const countr = await AppInstances.getCountrSdk()
    if (typeof countr.logError === 'function') {
      countr.logError({
        message,
        user: props.app.user._id,
        store: props.device.store,
        device: props.device._id,
        date: new Date().toISOString(),
        source: 'kds'
      })
    }
  }

  /**
   * Calculate the Cart List splited using the categories set
   */
  const splitCarts = useCallback(
    (cart, i) => {
      if (cart.status === 'completed') {
        return
      }
      let categoryProductsFormatted = []
      const restOfCategoriesProducts = []
      const settingsKitchenCategories =
        props.device.settings?.web_settings?.kitchen_categories ?? []

      let showTicket = false
      if (!settingsKitchenCategories.length) {
        categoryProductsFormatted = cart.items
        showTicket = true
      }

      for (let i = 0; i < cart.items.length; i++) {
        const item = cart.items[i]
        if (settingsKitchenCategories.length) {
          const catIsSet = item.product.categories.findIndex(category =>
            settingsKitchenCategories?.includes(category._id) 
            || (item.product.report_category?._id 
              && settingsKitchenCategories?.includes(item.product.report_category._id)
            )
          )

          if (~catIsSet) {
            categoryProductsFormatted.push({ ...item })
            if(item.status.find((el) => 
              ['pending','printed','ready', 'preparing'].indexOf(el.state) >= 0 && 
              el.amount > 0)
            ) showTicket = true
          } else {
            restOfCategoriesProducts.push({ ...item })
          }
        }
      }

      //Don't show ticket if no items for this category
      if(settingsKitchenCategories.length && !categoryProductsFormatted.length) return 

      //Don't show ticket if no items for this KDS in the right state
      if(!showTicket) return

      return (
        <Cart
          cart={cart}
          device={props.device}
          key={`${i}_${cart._id || cart.id}`}
          settings={props.settings}
          itemPerCategory={categoryProductsFormatted.reverse()}
          restItemsPerCategory={restOfCategoriesProducts}
          kitchenCategories={settingsKitchenCategories}
          currentEmbeddedDevice={props.app.currentEmbeddedDevice}
        />
      )
    },
    [props.app.currentEmbeddedDevice, props.device, props.settings]
  )

  const handleStatusToNumber = status => {
    let result = 0
    switch (true) {
      case status === 'preparing':
        result = 1
        break
      case status === 'ready':
        result = 2
        break
      case status === 'completed':
        result = 3
        break
    }

    return result
  }

  const handleShortList = useCallback((a, b) => {
    const aNumber = handleStatusToNumber(a.status)
    const bNumber = handleStatusToNumber(b.status)
    return aNumber > bNumber ? -1 : aNumber < bNumber ? 1 : 0
  }, [])

  const renderItems = useCallback(() => {
    const render = []
    const listSorted = props.carts.showingList.sort(CartUtils.compareOrderTime)

    if (listSorted.length) {
      if (props.settings.move_processing_ticket_first?.value) {
        listSorted.sort(handleShortList)
      }

      for (let i = 0; i < listSorted.length; i++) {
        const cart = listSorted[i]
        const renderCart = splitCarts(cart, i)
        if (renderCart) {
          render.push(renderCart)
        }
      }
    }

    return render
  }, [
    handleShortList,
    props.carts.showingList,
    props.settings.move_processing_ticket_first?.value,
    splitCarts
  ])

  return (
    <div
      id={`main`}
      className={renderItems().length ? 'withorders' : 'withoutorders'}>
      {props.carts.playTicketSound && (
        <div style={{ height: 0, width: 0 }}>
          <ReactAudioPlayer src={TicketSound} autoPlay controls />
        </div>
      )}
      {renderItems().length ? (
        <div id="grid-grouped-wrapper">
          <div id="grid-wrapper-main">{renderItems()}</div>
          <div id="grouped-wrapper">
            {props.device.settings.enable_groups &&
              props.device.settings.enable_groups.value && <Grouped />}
          </div>
        </div>
      ) : (
        <div className="no-orders">
          <p>{translate('no_open_orders')}</p>
          <i onClick={() => refreshOrders()} className="material-icons">
            gradient
          </i>
          <p>{translate('click_refresh')}</p>
        </div>
      )}
    </div>
  )
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainPage)
)
