import { memo, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux'

import EmployeesDropdown from './EmployeesDropdown'
import CartUtils from '../../utils/CartUtils'
import { AppInstances } from '../../utils/CountrSdk'

import StatusButtons from '../Shared/StatusButtons'

import './../../styles/Tools.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    employees: state.store.employees,
    device: state.device.device,
    store: state.store.store
  }
}

const Tools = memo(props => {
  const [selectedEmployee, setEmployee] = useState({})
  const [productsEmployee, setProductsEmployee] = useState({})

  const updateTransactionByIndex = async status => {
    const countr = await AppInstances.getCountrSdk()
    const emp = props.employees.find(e => e._id === selectedEmployee)
    const employee = {}

    if (emp) {
      employee._id = emp._id
      employee.name = emp.name
    }

    CartUtils.computeNextStatus(
      props.item,
      employee,
      status,
      props.prevState,
      props.currentIndex
    )

    props.cart.device = props.device._id

    props.cart.status = countr.calculateStatus(props.cart.items)
    props.updateTransaction(props.cart)

    if (
      (props.store?.options?.printDevice || localStorage.getItem('localDesktop')) 
      && status === 'ready') {
      const type = `${String(props.cart.__t).toLowerCase()}s`
      const getUniqueId = countr.retrieveCartEntryId(props.item.product)
      countr[type].print(props.cart._id, {
        device: localStorage.getItem('localDesktop') || props.store?.options?.printDevice,
        status: status,
        item: getUniqueId
      })
    }
  }

  const handleProductsEmployee = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()
    const entryId = countr.retrieveCartEntryId(props.item.product)
    if (props.cart.items?.length) {
      const item = props.cart.items.find(
        i => countr.retrieveCartEntryId(i.product) === entryId
      )
      if (item) {
        const employee =
          item.status[0]?.employees?.[item.status[0].employees.length - 1] ||
          null
        setProductsEmployee(employee)
      }
    }
  }, [props.cart.items, props.item.product])

  useEffect(() => {
    handleProductsEmployee()
  }, [handleProductsEmployee])

  return (
    <div className="tools">
      <div className="tools-header">
        <span>{props.item.product.name}</span>
      </div>
      <div className="btn-list single-item-tools">
        {!!props.employees?.length && (
          <EmployeesDropdown
            employees
            setEmployee={setEmployee}
            cartId={props.cart._id}
            selectedEmployee={productsEmployee}
          />
        )}
        <div className="tool-status-grid">
          {<StatusButtons updateStatus={updateTransactionByIndex} />}
        </div>
      </div>
    </div>
  )
})

export default connect(mapStateToProps, null)(Tools)
