import { memo } from 'react';
import { connect } from 'react-redux'
import { translate } from 'react-internationalization'

import CartUtils from '../../utils/CartUtils'

import { CartUtils as CartUtilsPkg } from '@countr/utils'

import './../../styles/cart.scss'

const mapStateToProps = state => {
  return {
    activeOrders: state.carts.filter,
    showEmployeeOnProduct: state.settings.show_employee_on_product,
    itemsToBulkUpdate: state.carts.itemsToBulkUpdate,
    showBulkUpdate: state.settings.bulk_update
  }
}

const mapDispatchToProps = () => {
  return {}
}

// eslint-disable-next-line prefer-const
const CartItem = memo(function CartItem({
  item,
  status,
  index,
  source,
  openTools,
  showCategorySeparator,
  showSeatSeparator,
  showCompleted,
  activeOrders,
  seat,
  showEmployeeOnProduct
}) {
  const categoriesFormatted =
    item.product?.categories.map(cat => cat.name) || []

  const _showCompletedItem = () => {
    if (!activeOrders) {
      return true
    }

    return status.state !== 'completed' ? true : showCompleted
  }

  const _getLastUpdate = () => {
    if (status.last_update) {
      const last = new Date(status.last_update)
      const time = `${last.getHours()}:${last.getMinutes()}:${last.getSeconds()}`

      return `${translate('last_update')}: ${time}`
    }

    return ''
  }

  return (
    <>
      {showCategorySeparator && (
        <div className="category-separator">
          {categoriesFormatted.map((cat, i) => (
            <span key={`cat_${i}`}>{cat}</span>
          ))}
        </div>
      )}
      {showSeatSeparator && (
        <div className="seat-separator">
          {seat !== '0' && (
            <>
              <i className="icon-change-seat"></i>
              <span>Seat {seat}</span>
            </>
          )}
        </div>
      )}

      {_showCompletedItem() && (
        <div
          className={`item product__${CartUtilsPkg.getCartEntryId(item)}__${
            status.state
          }`}
          onClick={() => openTools(item, index, status.state)}
          title={_getLastUpdate()}>
          <div className="left">
            <span className={`qtd-status__${status.state} qty`}>
              {source !== 'deliverect' ? status.amount : item.amount}x
            </span>
            <h2>{CartUtils.getItemName(item)}</h2>
            {/* <StatusIcon
              itemType={item}
              updateStatus={updateStatus}
              status={status.state}
            /> */}
          </div>

          {CartUtils.itemHasAddons(item) && (
            <div className="bottom">
              {item.product.current_addons.map((addon, i) => (
                <p className="cart-wrapper-addons" key={`${i}_${addon._id}`}>
                  + {addon.amount > 1 ? addon.amount + 'x ' : ''} {addon.name}{' '}
                  {(source === 'deliverect' && item.amount > 1) ||
                  (source !== 'deliverect' && status.amount > 1)
                    ? ' (' + translate('per_item') + ')'
                    : ''}
                </p>
              ))}
            </div>
          )}

          {item.status.length ? (
            <>
              {CartUtils.itemHasNote(item) && (
                <div className="right">
                  <p>
                    <i className="item-note">{`${translate('note')}: ${
                      item.note
                    }`}</i>
                  </p>
                </div>
              )}
              {showEmployeeOnProduct?.value &&
                CartUtils.itemStatusHasEmployee(status) && (
                  <div className="right cart-employee">
                    <span
                      className={`cart-wrapper-employee-status_${status.state}`}
                    />
                    <span className="cart-wrapper-employee">
                      {CartUtils.getItemStatusEmployee(status)}
                    </span>
                  </div>
                )}
            </>
          ) : (
            <div className="right">
              <span className="cart-wrapper-employee">
                <img
                  className="non-employee"
                  alt="non empoyee defined"
                  width="14"
                  src={require('./../../assets/images/non-employee.png')}
                />
              </span>
              <span className="cart-wrapper-employee">
                <img
                  className="non-employee"
                  alt="non empoyee defined"
                  width="14"
                  src={require('./../../assets/images/non-employee.png')}
                />
              </span>
              <span className={'cart-wrapper-employee-status_new'} />
            </div>
          )}
        </div>
      )}
    </>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(CartItem)
