/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import moment from 'moment'
import { AppInstances } from './CountrSdk'

const ACCEPTABLE_GIVEAWAY = ['giveaway', 'own_use']
const LOCAL_SOURCES = ['pos', 'web_pos', 'kiosk']

export default class CartUtils {
  static STATUS = ['new', 'pending', 'preparing', 'ready', 'completed']

  static getCartName = cart => {
    return cart.order_source !== 'deliverect' &&
      !!cart.extras &&
      !!cart.extras.deviceCartName &&
      cart.extras.deviceCartName !== cart.receipt_id
      ? cart.extras.deviceCartName.indexOf('KASSABON:') >= 0
        ? cart.extras.deviceCartName
        : `${cart.extras.deviceCartName}: #${cart.receipt_id}`
      : cart.receipt_id
  }

  static getCartItemsCount = cart => {
    return cart.items.reduce((acc, item) => acc + item.amount, 0)
  }

  static isPickupDeliveryOrder = order => {
    if (!!order.extras && !!order.extras.delivery) {
      const { pickupTime, deliveryTime } = order.extras.delivery
      return !!pickupTime || !!deliveryTime
    }

    return false
  }

  static isOrderPaid = order => {
    return order.__t === 'Transaction'
  }

  static checkOrderIsDelayed = (cart, warning_delay) => {
    if (!warning_delay) {
      return cart.status
    }

    const today = new Date()
    const { pickupTime, deliveryTime } = cart.extras.delivery || {}

    const timeUsed =
      cart.status_date ||
      (pickupTime ? pickupTime : deliveryTime ? deliveryTime : cart.date)

    const compareDelayTime = moment(timeUsed)
    const duration = moment.duration(compareDelayTime.diff(today))
    const mins = duration.asMinutes()

    if (warning_delay <= mins * -1) {
      return 'danger_delivery'
    }

    return cart.status
  }

  static isPickupOrder = order => {
    return (
      !!order.extras &&
      !!order.extras.delivery &&
      order.extras.delivery.pickupTime
    )
  }

  static isDeliveryOrder = order => {
    return (
      !!order.extras &&
      !!order.extras.delivery &&
      order.extras.delivery.deliveryTime
    )
  }

  static checkIfAsap = cart => {
    if (cart.extras && cart.extras.delivery) {
      if (cart.extras.delivery.deliveryIsAsap) {
        return true
      }
    }

    return false
  }

  static checkIfPostponed = cart => {
    if (cart.extras && cart.extras.delivery) {
      if (!cart.extras.delivery.deliveryIsAsap) {
        return true
      }
    }

    return false
  }

  static checkIfLocal = cart => {
    if (LOCAL_SOURCES.indexOf(cart.order_source) >= 0) return true
    return false
  }

  static getDate = date => {
    const now = new Date(date)
    return now.toLocaleDateString('en-GB')
  }

  static getTime = date => {
    const now = new Date(date)
    return now.toLocaleTimeString('en-GB')
  }

  static getStatusCount = (carts, status) => {
    if (carts) {
      return carts.reduce((acc, cart) => {
        return acc + (cart.status && cart.status === status ? 1 : 0)
      }, 0)
    }
  }

  static getActiveStatusCount = carts => {
    if (carts) {
      return carts.reduce((acc, cart) => {
        return (
          acc +
          (cart.status && cart.status !== 'completed' && cart.items.length
            ? 1
            : 0)
        )
      }, 0)
    }
  }

  static cleaningCart = async (countr, cartId) => {
    const cart = await countr.ws.carts.readOne(cartId)
    if (!cart) return

    const c = Object.assign({}, cart)
    c.customer = null
    c.total = 0
    c.sub_total = 0
    c.discount = 0
    c.reduction = {
      numeric: 0,
      percentage: 0
    }
    c.paid = 0
    c.payments = []
    c.items = []

    c.info = {}
    c.card_print_info = {
      clientTicket: ''
    }

    if (c.extras) c.extras.note = ''

    return c
  }

  /**
   * Method called just by <GeneralTools /> component
   */
  static moveGeneralCartStatus = (
    item,
    state,
    employee,
    hideCompletedSetting,
    settingsKitchenCategories
  ) => {
    const clonedItem = Object.assign(item, {})
    const productCategories = clonedItem.product.categories.map(
      category => category._id
    )
    if (clonedItem.product.report_category?._id)
      productCategories.push(clonedItem.product.report_category._id) //For Obur
    const hasCategory = productCategories.some(categoryId =>
      settingsKitchenCategories.includes(categoryId)
    )

    if (!hasCategory && settingsKitchenCategories.length) {
      return
    }

    const existCompleted = clonedItem.status.findIndex(
      status => status.state === 'completed' && status.amount > 0
    )
    const currentNew = clonedItem.status.find(status => status.state === 'new')

    if (hideCompletedSetting && ~existCompleted) {
      const completedStatus = clonedItem.status[existCompleted]

      const filteredHelper = clonedItem.status.filter(status => {
        if (
          status.state !== 'completed' &&
          status.state !== 'new' &&
          status.state !== state
        ) {
          status.amount = 0
          return status
        }
      })

      if (state !== 'completed') {
        filteredHelper.push({
          last_update: new Date().toISOString(),
          state: state,
          amount: clonedItem.amount - completedStatus.amount,
          employees: [employee]
        })
      } else {
        completedStatus.amount = clonedItem.amount
      }

      filteredHelper.push(completedStatus)
      filteredHelper.push(currentNew)

      clonedItem.status = filteredHelper
    } else {
      const filtered = clonedItem.status.filter(status => {
        if (status.state !== state && status.state !== 'new') {
          status.amount = 0
          return true
        }
      })

      filtered.push({
        last_update: new Date().toISOString(),
        state: state,
        amount: currentNew
          ? clonedItem.amount - currentNew.amount
          : clonedItem.amount,
        employees: [employee]
      })

      clonedItem.status = filtered

      if (currentNew) {
        clonedItem.status = clonedItem.status.concat(currentNew)
      }
    }

    return clonedItem
  }

  /**
   * Method called just by <Group />
   * @param  {} item
   * @param  {} employee
   * @param  {} state
   * @param  {} prevState
   */
  static computeGroupNextStatus = async (orders, group, state) => {
    const ordersToFilter = group.orders.map(order => order.id)
    const ordersToUpdate = orders.filter((order, i) => {
      return ordersToFilter.includes(order._id)
    })

    const countr = await AppInstances.getCountrSdk()
    for (let i = 0; i < ordersToUpdate.length; i++) {
      const order = ordersToUpdate[i]
      const groupOrders = group.orders.find(
        groupOrder => groupOrder.id === order._id
      )

      const { order_qty } = groupOrders
      const { id: groupItemId } = group

      for (let i = 0; i < order.items.length; i++) {
        const item = order.items[i]

        if (countr.retrieveCartEntryId(item.product) === groupItemId) {
          const clonedStatus = [...item.status]
          const statusExist = clonedStatus.find(
            status => status.state === state
          )

          const mutateStatus = clonedStatus.map(status => {
            if (
              status.state !== state &&
              status.state !== 'new' &&
              status.state !== 'completed'
            ) {
              status.amount = 0
            } else if (
              status.state !== 'new' &&
              status.state !== 'completed' &&
              status.state === state
            ) {
              // status.last_update = new Date().toISOString()
              status.amount = order_qty
            }

            return status
          })

          if (!statusExist) {
            mutateStatus.push({
              state,
              amount: order_qty,
              last_update: new Date().toISOString(),
              employees: []
            })
          }

          item.status = mutateStatus
        }
      }
    }

    return ordersToUpdate
  }

  /**
   * Method called just by <Tools /> and <Groups /> Components
   * @param  {} item
   * @param  {} employee
   * @param  {} state
   * @param  {} prevState
   */
  static computeNextStatus = (item, employee, state, prevState) => {
    const statusExistIndex = item.status.findIndex(
      currentState => currentState.state === state
    )

    const lastStatusIndex = item.status.findIndex(lastState => {
      if (prevState) {
        return lastState.state === prevState && lastState.amount > 0
      }
    })

    const lastStatus = Object.assign({}, item.status[lastStatusIndex])

    if (!lastStatus) {
      throw Error(`Not possible to find last status of item ${item.name}`)
    }

    if (!~statusExistIndex) {
      item.status.push({
        last_update: new Date().toISOString(),
        state: state,
        amount: lastStatus.amount,
        employees: [employee]
      })

      lastStatus.amount = 0
      item.status[lastStatusIndex] = lastStatus
    } else {
      const newStatusObj = Object.assign({}, item.status[statusExistIndex])
      newStatusObj.employees = [employee]

      if (lastStatus.state !== state) {
        newStatusObj.amount += lastStatus.amount
        lastStatus.amount = 0
      } else {
        if (newStatusObj.amount !== lastStatus.amount) {
          lastStatus.amount = 0
        }

        newStatusObj.amount = lastStatus.amount
        lastStatus.employees = [employee]
      }

      item.status[statusExistIndex] = newStatusObj
      item.status[lastStatusIndex] = lastStatus
    }

    // lastStatus.last_update = new Date().toISOString()

    return item
  }

  static getPropertyTimeValue = order => {
    if (order.status_date) return order.status_date

    if (order.extras.delivery) {
      const { pickupTime, deliveryTime } = order.extras.delivery
      return pickupTime ? pickupTime : deliveryTime ? deliveryTime : order.date
    }

    return order.date
  }

  static compareOrderTime = (order1, order2) => {
    const time1 = this.getPropertyTimeValue(order1)
    const time2 = this.getPropertyTimeValue(order2)
    return time1 < time2 ? -1 : time1 > time2 ? 1 : 0
  }

  static getStatusByKey = (key, hotkeys) => {
    let status = 'printed'
    Object.keys(hotkeys).forEach(st => {
      if (hotkeys[st] === key) {
        status = st === 'new' ? 'printed' : st
      }
    })

    return status
  }

  static itemHasNote = item => {
    return !!item.note
  }

  static itemStatusHasEmployee = status => {
    return (
      !!status.employees &&
      !!status.employees.length &&
      !!status.employees[0].name
    )
  }

  static getItemStatusEmployee = status => {
    return status.employees[0].name
  }

  static getItemName = item => {
    let isNameDefault = false
    if (
      (item.product.current_variant?.name?.toLowerCase() === 'default' &&
        item.product.current_variant.default) ||
      item.product.current_variant.name === item.product.name
    ) {
      isNameDefault = true
    }

    return isNameDefault
      ? item.product.name
      : `${item.product.name} (${item.product.current_variant.name || ''})`
  }

  static itemHasAddons = item => {
    return !!item.product.current_addons && !!item.product.current_addons.length
  }

  static isGiveaway = order => {
    return (
      !!order?.extras?.giveaway_reason &&
      !this.isAcceptableGiveaway(order.extras.giveaway_reason)
    )
  }

  static isAcceptableGiveaway = reason => {
    return ACCEPTABLE_GIVEAWAY.includes(reason)
  }

  static getItemsKdsAmount = items => {
    let amount = 0
    items.forEach(i => {
      if (i.status.length > 1) {
        amount += i.status[1].amount
      }
    })

    return amount
  }

  static returnItemStatusColor = item => {
    const { status } = item
    const itemStatus =
      !!status && !!status.length && !!status[status.length - 1]
        ? status[status.length - 1].state || ''
        : ''

    switch (itemStatus) {
      case 'new':
        return '#eb5656'
      case 'pending':
        return '#FFD65C'
      case 'printed':
        return '#FFD65C'
      case 'preparing':
        return '#f79d34'
      case 'ready':
        return '#3bae70'
      case 'completed':
        return '#64c2ea'
      default:
        return '#dedede'
    }
  }
}
