import { memo, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { Text, translate } from 'react-internationalization'

import { useNavigate } from 'react-router-dom'

import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'

import { setLoading, showToast } from '../store/actions/app'
import { setStoreId, setStore } from '../store/actions/store'
import { setDevice } from '../store/actions/device'

import CountrResources from '../utils/CountrResources'
import { AppInstances } from '../utils/CountrSdk'
import DeviceUtils from '../utils/DeviceUtils'
import Logo from '../components/Shared/Logo'

import './../styles/Login.scss'

const APP_VERSION = process.env.REACT_APP_VERSION

const mapStateToProps = state => {
  return {
    app: state.app,
    store: state.store
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: state => dispatch(setLoading(state)),
    showToast: msg => dispatch(showToast(msg)),
    setStoreId: id => dispatch(setStoreId(id)),
    setStore: store => dispatch(setStore(store)),
    setDevice: device => dispatch(setDevice(device))
  }
}

const StorePage = memo(props => {
  const resource = useRef(new CountrResources())
  const [stores, setStore] = useState([])
  const [deviceName, setDeviceName] = useState('')
  const [selectedStore, setSelectedStore] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    initStoreSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initStoreSelection = () => {
    const store = localStorage.getItem('KDS:SelectedStore')

    if (!!props.store && !!props.store.device) {
      props.setStoreId(store)
      navigate('/main')
    } else {
      _loadStores()
    }
  }

  const _loadStores = async () => {
    props.setLoading(true)

    try {
      const loadedStores = await resource.current.getResource('stores')
      setStore(loadedStores)
      props.setLoading(false)
    } catch (error) {
      props.showToast(error.message || JSON.stringify(error))
      props.setLoading(false)
    }
  }

  const getSelectedStore = () => {
    return stores.find(store => {
      if (store._id === selectedStore) {
        delete store.devices
      }

      return store
    })
  }

  const checkboxStores = e => {
    const { checked, value } = e.target

    if (!selectedStore.includes(value)) {
      setSelectedStore([...selectedStore, value])
    } else if (!checked) {
      setSelectedStore(selectedStore.filter(id => id !== value))
    }
  }

  const handleClickProceed = () => {
    if (!selectedStore.length) {
      props.showToast(translate('you_must_select_a_store'))
      return
    } else if (!deviceName.length) {
      props.showToast(translate('deviceid_mandatory'))
      return
    }

    props.setStore(getSelectedStore())
    props.setStoreId(selectedStore)
    localStorage.setItem('KDS:SelectedStore', selectedStore)

    AppInstances.getCountrSdk().then(async countr => {
      const uuid = await DeviceUtils.generateUUID()
      const deviceObj = {
        store: selectedStore[0],
        name: deviceName,
        uuid,
        merchant: props.app.user._id,
        info: {
          platform: navigator.platform,
          useragent: navigator.userAgent,
          version: `kds - ${APP_VERSION}`
        },
        settings: {
          language: 'en'
        }
      }

      const deviceDefault = await countr.devices
        .create(deviceObj)
        .catch(error => {
          AppInstances.logError({
            message: JSON.stringify(error),
            user: props.app.user._id,
            store: getSelectedStore()._id
          })
        })

      if (deviceDefault && deviceDefault._id) {
        const device = await countr.devices
          .update(deviceDefault._id, {
            settings: {
              storesListener: selectedStore,
              ...deviceDefault.settings
            }
          })
          .catch(error => {
            AppInstances.logError({
              message: JSON.stringify(error),
              user: props.app.user._id,
              store: getSelectedStore()._id,
              device: deviceDefault._id
            })
          })

        props.setDevice(device)
        localStorage.setItem('uuid', uuid)
        navigate('/main')
      }
    })
  }

  return (
    <div id="app-login">
      <div className="login-content">
        <Logo />
        <TextField
          fullWidth
          autoFocus
          className="device-name"
          color="primary"
          variant="outlined"
          id="deviceName"
          label={<Text id="addid_device" />}
          value={deviceName}
          onChange={event => setDeviceName(event.target.value)}
        />
        <div className="store-selection-login">
          <FormControl component="fieldset" margin="dense">
            <FormGroup>
              {stores.map(store => (
                <FormControlLabel
                  className="stores-label"
                  key={store._id}
                  control={
                    <Checkbox onChange={checkboxStores} value={store._id} />
                  }
                  label={store.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
        <div className="notes">
          <p>
            * <Text id="select_stores" />
          </p>
        </div>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleClickProceed}
          className="save-store-btn">
          {props.app.isLoading ? (
            <CircularProgress className="btn-loader" />
          ) : (
            <Text id="save" />
          )}
        </Button>
      </div>
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(StorePage)
