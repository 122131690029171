/* eslint-disable no-debugger */
import {
  SET_CARTS,
  MOVE_POSTPONED,
  SET_POSTPONED,
  UPDATE_CART,
  UPDATE_CART_BYID,
  SET_CART_TO_REFUND,
  SET_CART_TO_TRANSACTION,
  SET_ALL_CANCELLED,
  SET_CANCELLED,
  TICKET_SOUND,
  SET_SHOWING_CARTS,
  COMPLETED_CARTS
} from '../actions/actionTypes'

const initialState = {
  completedList: [],
  postponed: [],
  showingList: [],
  cancelled: [],
  filter: true,
  playTicketSound: false,
  itemsToBulkUpdate: []
}

const cartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOVE_POSTPONED:
      if (action.payload && action.payload._id) {
        const movePostponed = [...state.postponed].concat(action.payload)
        return {
          ...state,
          postponed: movePostponed.filter(
            c => c._id !== action.payload._id && c.status !== 'completed'
          )
        }
      } else {
        return state
      }

    case SET_POSTPONED:
      // eslint-disable-next-line no-case-declarations
      const newPostponed = [...state.postponed].concat(action.payload)
      return {
        ...state,
        postponed: newPostponed.filter(c => c.status !== 'completed')
      }

    case SET_SHOWING_CARTS:
      return {
        ...state,
        showingList: action.payload
      }

    case SET_CARTS:
      return {
        ...state,
        showingList: [...action.payload].filter(c => {
          return c.status !== 'completed'
        })
      }

    case SET_CART_TO_TRANSACTION:
      const elExists = 
        state.showingList.find((el) => (el._id || el.id) === action.payload.cart.extras.orderSource)
      if(elExists) return {
        ...state,
        showingList: state.showingList.map(cart => {
          if (
            (cart._id || cart.id) === action.payload.cart.extras.orderSource
          ) {
            return action.payload.cart
          }

          return cart
        })
      }
      else {
        return {
          ...state,
          showingList: [
            ...state.showingList,
            action.payload.cart
          ],
          playTicketSound: action.payload.cart.items.length ? true : false,
          printDelivery: action.payload.cart.order_source === 'deliverect' 
            ? { _id: action.payload.cart._id, __t: action.payload.cart.__t }
            : null
        }
      }

    case SET_CART_TO_REFUND:
      return {
        ...state,
        completedList: state.completedList.filter(
          cart => cart._id !== action.payload.transaction_source
        ),
        showingList: state.showingList.filter(
          cart => cart._id !== action.payload.transaction_source
        ),
        cancelled: [action.payload].concat([...state.cancelled])
      }

    case UPDATE_CART_BYID:
      return {
        ...state,
        showingList: state.showingList
          .map(cart => {
            if (cart._id === action.payload.cart._id) {
              cart = action.payload.cart
            }

            return cart
          })
          .filter(c => c.status !== 'completed' && c.status !== 'cancelled')
      }

    case UPDATE_CART: {
      const completed = [...state.completedList]

      if (
        action?.payload?.cart?.status === 'completed' ||
        action?.payload?.cart?.status === 'cancelled'
      ) {
        completed.push(action.payload.cart)
      }

      //Handle empty cart coming in - set as completed so any items already in KDS are cleared
      //Also, set status to 'completed' if empty items
      if(!action?.payload?.cart?.items?.length) {
        action.payload.cart.status = 'completed'
      }

      if (!state.showingList.length) {
        const result = [action.payload.cart]
        return {
          ...state,
          playTicketSound: action.payload.cart.items.length ? true : false,
          showingList: result.filter(c => c.status !== 'completed' && c.status !== 'cancelled'),
          completedList: completed
        }
      } else {
        let exist = false

        const parced = [...state.showingList].map(cart => {
          const toCompare = action.payload.cart._id

          if (cart._id === toCompare || cart.id === toCompare) {
            cart = action.payload.cart
            exist = true
          }

          return cart
        })

        if (!exist) {
          parced.push(action.payload.cart)
        }

        //Don't play sound for empty cart in update
        return {
          ...state,
          playTicketSound: !exist && action.payload.cart.items.length ? true : false,
          showingList: parced.filter(c => c.status !== 'completed' && c.status !== 'cancelled'),
          completedList: completed
        }
      }
    }
    case SET_ALL_CANCELLED: {
      return {
        ...state,
        cancelled: [...action.payload].concat([...state.cancelled])
      }
    }

    case SET_CANCELLED: {
      return {
        ...state,
        cancelled: [action.payload].concat([...state.cancelled])
      }
    }

    case TICKET_SOUND: {
      return {
        ...state,
        playTicketSound: action.payload
      }
    }

    case COMPLETED_CARTS: {
      return {
        ...state,
        completedList: action.payload
      }
    }

    default:
      return state
  }
}

export default cartsReducer
